import { useFetcher, useRouteLoaderData } from '@remix-run/react';

import { loader } from '~/root.tsx';

import { useEffect } from 'react';
import { $path } from 'remix-routes';

export const SetTimeZone = () => {
  const loaderData = useRouteLoaderData<typeof loader>('root');
  const fetcher = useFetcher<unknown>();
  const timeZone = loaderData?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    if (!loaderData?.timezone) {
      try {
        fetcher.submit(
          { timeZone },
          { method: 'post', action: $path('/api/internal/user/set-timezone') },
        );
      } catch (e) {}
    }
  }, []);
  return null;
};
